<template>
    <section class="SigninSection">
		<div class="Container">
			<div class="Box">
                <div class="Frame">
                    <a :href="$router.resolve({ name: 'DettaglioProdotto', params: { ProductId: this.$route.params.ProductId, CodiceSocieta: this.$route.params.CodiceSocieta } }).href"><img src="images/BackButtonCircle.png" style="width:35px;"/></a>
                    <div style="height:24px;"/>
                    <h1>Guida alle taglie: </h1>
                    <div class="Widget MainText" ></div>
                    <div style="height:15px;"/>
                    <VueImageZoomer
                        :regular="this.img"
                        :zoom="this.img"
                        :zoom-amount="2"
                        img-class="img-fluid"
                        alt="Guida alle Taglie"
                        @on-zoom="zoom = true"
                        @off-zoom="zoom = false" 
                    >
                    </VueImageZoomer >
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import { VueImageZoomer } from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';

export default {
    name: "ZoomFile",
    data(){
        return {
            img: null
        }
    },
    components:{
        VueImageZoomer
    },
    mounted:function(){
        this.img = 'images/' + this.$route.params.IdImg;
    }
}
</script>